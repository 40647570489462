import InAppSpy from 'inapp-spy';

import { getStaticAssetPath } from '../utils/assets';
import {
  deleteKnownRedirectParam,
  getKnownRedirectParam,
} from '../utils/redirect-to';
import { getAgentInfo } from '../utils/user-agent';
import { useCancelConfirmModalStateRoot } from './ConfirmCancelModalContext';
import { ModalWrapper } from './ConfirmCancelModalContext/ModalWrapper';
import { InfoIcon } from './icons/InfoIcon';

export type EscapeData = NonNullable<ReturnType<typeof deriveEscapeData>>;

export function deriveEscapeData(url: URL) {
  const inAppEscape = getKnownRedirectParam(url.searchParams, 'in-app-escape');

  if (!inAppEscape || inAppEscape === 'force-prompt') {
    const info = getAgentInfo();
    const { isInApp, appKey, appName } = InAppSpy();
    const isInIframe = window.self !== window.top;

    if (isInApp || isInIframe || inAppEscape === 'force-prompt') {
      // We are going to escape so remove the param in the event that it is set
      // to "force". In the future we may want to add a new state to the param
      // such as "attempted", which could indicate to the UI that if it is still
      // in-app/iframed, the escape was unsuccessful.
      deleteKnownRedirectParam(url.searchParams, 'in-app-escape');

      // See https://inappdebugger.com/ for the latest techniques.
      const safariEscapeUrl = `x-safari-${url.toString()}`;
      const protocol = url.protocol;
      url.protocol = '';
      const androidEscapeUrl = `intent://${url.toString()}#Intent;scheme=${protocol};end`;
      const iframeEscapeUrl = `${url.toString()}`;

      return {
        isInApp,
        isInIframe,
        appKey: appKey ?? null,
        appName: appName ?? null,
        safariEscapeUrl,
        androidEscapeUrl,
        iframeEscapeUrl,
        isAndroid: info.os.isAndroid,
        isIOS: info.os.isIOS,
      };
    }
  }
}

function EscapeLink(props: EscapeData) {
  const {
    safariEscapeUrl,
    androidEscapeUrl,
    iframeEscapeUrl,
    isAndroid,
    isIOS,
  } = props;

  const className =
    'block text-[#4CE3B3] text-base font-bold bg-main-layer border border-secondary rounded-2xl px-5 py-3';
  const label = 'Let’s Get Started';

  return isIOS ? (
    <a href={safariEscapeUrl} className={className}>
      {label}
    </a>
  ) : isAndroid ? (
    <a href={androidEscapeUrl} className={className}>
      {label}
    </a>
  ) : (
    <a
      href={iframeEscapeUrl}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
    >
      {label}
    </a>
  );
}

type InAppEscapeProps = {
  escapeData: EscapeData;
};

const bgDistribute = getStaticAssetPath('images/bg-distribute.png');

export function InAppEscape({ escapeData }: InAppEscapeProps) {
  const { isInApp } = escapeData;

  const why = isInApp ? (
    <>
      <h2 className='text-2xl font-bold'>Why can't I experience it here?</h2>
      <p>
        We have detected you're viewing this page within an app. In-app browsers
        sometimes limit certain features, like microphone or video playback.{' '}
        <span className='font-bold'>For the best experience</span>, please open
        this content in your default browser.
      </p>
    </>
  ) : (
    <>
      <h2 className='text-2xl font-bold'>Why can't I experience it here?</h2>
      <p>
        You're viewing this page in an embedded window, which can limit certain
        features, like microphone or full-screen video.{' '}
        <span className='font-bold'>For the best experience</span>, please open
        this content in a new window.
      </p>
    </>
  );

  const message = isInApp
    ? `Click to open this content in your system browser for the best experience!`
    : `Click to open this content in a new window for the best experience!`;

  const [modal, awaitConfirmCancelModal] = useCancelConfirmModalStateRoot();

  return (
    <div
      className='
        h-full w-full max-w-240
        mx-auto p-5 pb-0
        flex flex-col items-center gap-4
        text-white
      '
    >
      <div
        className='absolute inset-0 z-[-1]'
        style={{
          background: 'linear-gradient(112.3deg, #111111 0%, #0B2D23 100%)',
        }}
      >
        <img src={bgDistribute} alt='' className='object-cover w-full h-full' />
      </div>

      <div className='flex-1 flex flex-col justify-center items-center px-5 max-w-150'>
        <h1
          className='
            text-xl lg:text-2xl font-bold mb-7 text-center
            flex flex-col items-center gap-2
          '
        >
          {message}
        </h1>
        <EscapeLink {...escapeData} />

        {modal}
      </div>

      <div className='absolute bottom-5 right-5'>
        <button
          type='button'
          onClick={() => {
            awaitConfirmCancelModal({
              kind: 'custom',
              element: (p) => (
                <ModalWrapper
                  containerClassName='w-160'
                  innerClassName='overflow-hidden px-8 py-8 flex flex-col items-center gap-8'
                  borderStyle='gray'
                  onClose={p.internalOnCancel}
                >
                  {why}
                  <EscapeLink {...escapeData} />
                </ModalWrapper>
              ),
            });
          }}
          style={{
            aspectRatio: '1/1',
          }}
        >
          <InfoIcon className='w-6 h-6 fill-current' />
        </button>
      </div>
    </div>
  );
}
